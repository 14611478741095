.partner {
  width: 100%;

  &__media {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    &-image {
      position: relative;
      width: 100%;
      height: 100%;

      &:after {
        content: '';
        position: relative;
        display: block;
        padding-bottom: 60%;
        width: 100%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .partner__content {
    margin-bottom: var(--sections-gap);
  }

  blockquote {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      padding: 0 $grid-gutter-width;
    }

    p {
      font-style: italic;
      text-align: center;
    }

    cite {
      font-style: normal;
      font-weight: 700;
      float: right;
    }
  }
}
