.hero {
  min-height: 50vh;
  max-height: calc(100 * var(--vh));
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: var(--sections-gap);
  padding: 0;

  &:after {
    content: '';
    position: relative;
    pointer-events: none;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 36.7845%;
  }

  &--is-fullscreen {
    width: 100%;
    height: 100vh;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  &__video,
  &__image,
  &__map {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: #ccc;
  }

  &__video,
  &__image,
  &__image img {
    object-fit: cover;
    position: absolute;
    object-position: center;
    pointer-events: none;
  }

  &__image img {
    width: 100%;
    height: 100%;
  }

  &__video {
    background: transparent;
    pointer-events: none;
  }

  &__title {
    padding: 0 15px;
    position: absolute;
    width: 100%;
    max-width: 100%;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      max-width: 80%;
    }

    &-inner {
      display: block;
      color: white;
      text-align: left;
      font-family: brandon-grotesque, sans-serif;
      line-height: 1.6;
      font-weight: 700;
      font-size: var(--hero-title);

      .neos-backend & .neos-inline-editable span {
        background: $primary;
      }

      &__line {
        padding: 0 1rem;
        position: relative;
        display: inline-block;
        margin-bottom: .15em;
        color: transparent;
        transition: 0s color ease-in-out;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }

        &:before {
          background: $primary;
          opacity: 0;
          z-index: -1;
          transition-duration: 0s;
          transition-property: opacity;
          transition-timing-function: cubic-bezier(.5,.5,0,1);
        }

        &:after {
          transform: scaleX(0);
          background: $primary;
        }

        @for $i from 1 through 10 { // lines
          $time: ($i) / 5;

          &:nth-child(#{$i}) {
            transition-delay: #{$time + .4}s;

            &:before {
              transition-delay: #{$time + .4}s;
            }

            &:after {
              animation-delay: #{$time}s;
            }
          }
        }
      }
    }

    &--is-hidden {
      opacity: 0;
    }

    &--is-animated .hero__title-inner__line {
      color: $white;

      &:before {
        opacity: 1;
      }

      &:after {
        animation-duration: .8s;
        animation-name: shrink;
        animation-timing-function: cubic-bezier(.5,.5,0,1);
        animation-fill-mode: forwards;
      }
    }
  }

  &__icon {
    position: absolute;
    z-index: 2;
    width: 31px;
    height: 70px;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);

    @include media-breakpoint-up(sm) {
      bottom: 70px;
    }

    @include media-breakpoint-up(md) {
      bottom: 100px;
    }

    .mouse-arrow {
      animation: 2s bounce-sm infinite ease-in-out;

      @include media-breakpoint-up(sm) {
        animation: 2s bounce infinite ease-in-out;
      }
    }

    .mouse {
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    svg {
      overflow: visible;
      padding-bottom: 30px;
    }
  }
}


@keyframes bounce {
  0%, 100% {
    transform: translate(0, 0)
  }
  35% {
    transform: translate(0, 30px)
  }
}


@keyframes shrink {
  0% {
    transform-origin: left center;
    transform: scaleX(0);
  }
  49.9999% {
    transform-origin: left center;
  }
  50% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right center;
  }
}
