.image {
  &-section {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    min-height: 1px; // IE 11 bug workaround, see https://github.com/philipwalton/flexbugs/issues/75
    padding: 0;
    margin: var(--sections-gap) 0 var(--sections-gap);

    .content-container {
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }

    .lottie-player {
      z-index: 40;
      max-width: 33.3333vw;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
      pointer-events: none;

      @include media-breakpoint-down(sm){
        max-width: 50%;
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      width: 90%;
    }

    @include media-breakpoint-up(md) {
      width: 80%;
    }

    @include media-breakpoint-up(lg) {
      width: 66.6666%;
    }
  }

  .image-caption {
    color: $dark;
    outline: none !important;
    padding: #{$grid-gutter-width / 2};
    background: $blue;
    display: block;
    width: 100%;
    max-width: 100%;
    float: right;
    z-index: 2;
    position: relative;
    text-decoration: none;
    transition: .3s background ease-in-out;

    @include media-breakpoint-up(sm) {
      max-width: 90%;
      margin-top: var(--n-sections-gap);
      margin-bottom: var(--n-sections-gap);
    }

    @include media-breakpoint-up(md) {
      padding: 40px 50px;
      margin-right: -40px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 60%;
    }

    & > span {
      display: block;
    }

    & > span.show-more {
      display: inline-block;
    }

    &:hover {
      background: darken($blue, 10%);
    }

    &:hover .show-more,
    &:focus .show-more {
      &:before {
        transform: translate(-50%, 0) scale(0);
      }

      &:after {
        transform: translate(5px, 0);
      }
    }
  }
}

.image-section__figure {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;

  img {
    display: block;
    width: 100%;
    height: auto;
    background-color: $grey-light;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($black, .2);
    padding: 28px 44px 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: $white;
    font-weight: 700;
    font-size: var(--p);
    letter-spacing: .015em;
    line-height: 1.3;

    @include media-breakpoint-down(md) {
      padding: 10px 25px;
      font-size: rem(16);
      letter-spacing: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 12px 20px;
      font-size: rem(14);
      line-height: 1.2;
      background-color: rgba($black, .4);
    }
  }
}
