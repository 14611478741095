.hash-menu {
  padding: 0 15px;
  position: relative;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }

  &__list {
    margin: 0;
    padding: 15px 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    gap: 70px;

    @media screen and (max-width: 1630px) {
      gap: 50px;
    }

    @media screen and (max-width: 1520px) {
      gap: 40px;
    }

    @media screen and (max-width: 1440px) {
      gap: 30px;
    }

    @media screen and (max-width: 1366px) {
      gap: 25px;
    }
  }

  &.is-scrollable--prev &__navigation-button--prev,
  &.is-scrollable--next &__navigation-button--next {
    opacity: 1;
    pointer-events: auto;
  }

  &__item {
    &.is-active .hash-menu__link {
      color: $primary;
    }

    &.is-inactive .hash-menu__link {
      color: rgba($grey, .3);
    }
  }

  &__link {
    text-decoration: none !important;
    font-weight: 700;
    letter-spacing: .015em;
    font-size: var(--h5);
    text-transform: uppercase;
    transition: .2s color ease-in-out;
    white-space: nowrap;

    @media screen and (max-width: 1366px) {
      letter-spacing: 0;
    }
  }

  &__navigation-button {
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 100%;
    min-height: 40px;
    transform: translate(0, -50%);
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    transition: .2s opacity ease-in-out;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 13px;
      height: 13px;
      margin-top: -1px;
      border-top: 2px solid $primary;
      border-right: 2px solid $primary;
    }

    &--prev {
      left: 0;
      background: linear-gradient(to right, $white 66%, rgba(255, 255, 255, 0) 100%);

      &:before {
        left: 50%;
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }

    &--next {
      right: 0;
      background: linear-gradient(to left, $white 66%, rgba(255, 255, 255, 0) 100%);

      &:before {
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }
}
