.items-list {
  margin: 0 -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include media-breakpoint-up(lg) {
    margin: 0 -20px;
  }

  &__item {
    overflow: hidden;
    width: 100%;
    max-width: 50%;
    padding: 0 10px;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
      padding: 0 20px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 33.3333%;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 25%;
    }

    &-inner {
      width: 100%;
    }

    &-preview-content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      transform: scale(1);
      transform-origin: bottom center;

      @include media-breakpoint-up(xl) {
        flex-wrap: nowrap;
      }
    }

    &-image {
      min-width: 100%;
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      background: $grey-light;
      transition: .25s transform ease-in-out;

      @include media-breakpoint-up(xl) {
        min-width: calc(20vw - 40px);
        width: calc(20vw - 40px);
        height: calc(20vw - 40px);
      }

      @include media-breakpoint-up(xxl) {
        min-width: calc(15vw - 40px);
        width: calc(15vw - 40px);
        height: calc(15vw - 40px);
      }

      &:after {
        content: '';
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        display: block;
      }

      img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    &:hover:not(.items-list__item--is-active) .items-list__item-image {
      transform: scale(1.05) translateX(-0.025%) translateY(-0.025%);
    }

    &-sign {
      display: block;
      background: $white;
      font-size: 1.2rem;
      line-height: 1.2;
      font-style: normal;
      width: 100%;
      min-height: 97px;
      max-height: 200px;
      overflow: hidden;
      padding: 15px;
      transform: scale(1);
      transform-origin: top center;
      transition: .25s transform ease-in-out;

      @include media-breakpoint-down(sm) {
        // mobile content has normally 4 lines
        min-height: 118px;
      }

      &__name {
        font-weight: 700;
        display: block;
      }

      &__position {
        font-size: 1.1rem;
        font-weight: 400;
        display: block;
      }
    }

    &-content {
      display: none;
      max-width: 0;
    }
  }

  &__title {
    margin-bottom: 1.6666em;
  }
}


// new popup logic
.items-list__item--is-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  bottom: 0;
  padding: 85px 20px; // mobile header height 85px
  margin: 0;
  background-color: $menu-bg;
  z-index: 20;
  pointer-events: none;
  backdrop-filter: blur(5px);
  opacity: 0;
  will-change: opacity;
  transition: .3s opacity .3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: calc(20px - var(--scrollbar-width)) !important;

  @media (max-height: 500px) {
    // don't align middle, but use all the space we have
    padding-bottom: 15px;
  }
  @include media-breakpoint-up(lg) {
    padding: #{$header-height + 10}px 20px;
  }

  .items-list__item-inner {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    width: 90%;
    max-height: 90%;
    max-width: 800px;
    padding: 40px 40px;
    background: $white;
    overflow-scrolling: touch;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    opacity: 0;
    transform: translate(var(--scrollbar-width), 0);
    transition: .3s opacity .2s ease-in-out, .2s box-shadow ease-in-out;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 20px 20px;
    }
  }

  .items-list__item {
    &-preview-content {
      @include media-breakpoint-down(sm) {
        width: 100%;
        display: none;
      }
    }

    &-image {
      transform: none !important;

      @include media-breakpoint-up(sm) {
        width: 250px;
        height: 250px;
      }
    }

    &-sign {
      display: none;
    }

    &-content {
      display: block;
      max-width: none;
      padding-left: 20px;

      @include media-breakpoint-down(sm) {
        padding-top: 0;
        padding-left: 0;
        width: 100%;
      }

      h3 {
        margin-top: -2px;
        padding-left: 10px;
      }
    }
  }
}

body.popup-open {
  overflow: hidden;
  padding-right: var(--scrollbar-width);

  main {
    overflow: visible;
  }

  .items-list__item--is-popup {
    opacity: 1;
    pointer-events: auto;
    padding-right: 20px;
    transition: .3s opacity ease-in-out;

    .items-list__item-inner {
      opacity: 1;
      box-shadow: 0 0 25px rgba(0, 0, 0, .15);
    }
  }

  .page-header {
    padding-right: var(--scrollbar-width);
  }
}
