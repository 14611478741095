.text-teaser {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: nowrap;
  position: relative;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .image-section__figure {
    width: 100%;
    min-width: 40%;
    margin: 0 auto auto;

    @include media-breakpoint-down(md) {
      max-width: 240px;
      margin: auto;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin: 0;
    }

    figcaption {
      padding: 22px 40px;
      height: auto;
    }
  }

  .teaser-image {
    width: 100%;
    position: relative;
    min-width: 180px;
    max-height: 240px;
    background: $grey;

    @include media-breakpoint-up(md) {
      min-width: 230px;
      max-height: 320px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 270px;
      max-height: 100%;
    }

    &:after {
      content: '';
      position: relative;
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .teaser-content {
    padding: 15px 0;
    background: $white;
    min-width: 50%;
    flex-grow: 1;
    order: 2;

    @include media-breakpoint-up(lg) {
      order: 1;
    }

    @include media-breakpoint-up(md) {
      padding: 40px 50px 40px 0;
    }

    &, p {
      font-size: var(--p-small);
    }

    blockquote {
      margin: 0;
      max-height: 270px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__title {
      @extend .h2;
      @extend .h3;
    }

    // always show the scrollbar
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  .teaser-image + .teaser-content,
  .image-section__figure + .teaser-content {
    @include media-breakpoint-up(lg) {
      padding-right: 0;
      padding-left: 50px;
    }
  }

  .teaser-content + .teaser-image,
  .teaser-content + .image-section__figure {
    order: 1;

    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }

  .teaser-sign {
    @extend .p;
    font-style: normal;
    margin-top: 30px;
    display: block;
    clear: both;

    &-name {
      font-weight: 700;
      display: block;

      &:before {
        content: '-';
        display: inline-block;
        float: left;
        margin-right: 0.2rem;
      }
    }

    &-position {
      display: block;
    }
  }

  &--align-bottom {
    .teaser-content {
      padding-bottom: 0;
      padding-top: 0;
      margin-bottom: 0;
      margin-top: auto;

      p {
        font-size: var(--p);
      }
    }

    .image-section__figure {
      margin: auto auto 0;

      @include media-breakpoint-down(md) {
        margin: 0 auto 20px;
      }
    }
  }

  &--align-top {
    .teaser-content {
      padding-bottom: 0;
      padding-top: 0;
      margin-top: 0;
      margin-bottom: auto;

      p {
        font-size: var(--p);
      }
    }

    .image-section__figure {
      margin: 0 auto auto;

      @include media-breakpoint-down(md) {
        margin: 0 auto 20px;
      }
    }
  }

  &--align-middle {
    .teaser-content {
      padding-bottom: 0;
      padding-top: 0;
      margin-top: auto;
      margin-bottom: auto;

      p {
        font-size: var(--p);
      }
    }

    .image-section__figure {
      margin: auto;

      @include media-breakpoint-down(md) {
        margin: 0 auto 20px;
      }
    }
  }

  &--50x50 {
    & > .teaser-content,
    & > .teaser-image,
    & > .image-section__figure {
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &--40x60 {
    & > .teaser-content--left,
    & > .teaser-image,
    & > .image-section__figure {
      width: 40%;
    }

    & > .teaser-content--right,
    & > .teaser-content--left + .teaser-image,
    & > .teaser-content--left + .image-section__figure {
      width: 60%;
    }

    & > .teaser-content,
    & > .teaser-image,
    & > .image-section__figure {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &__link-overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

