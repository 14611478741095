.contact {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: nowrap;

  @include media-breakpoint-up(md){
    flex-direction: row;
  }

  &-image {
    position: relative;
    max-height: 400px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md){
      margin: 0;
      width: 55%;
    }

    &:after {
      content: '';
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 66.6666%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    @include media-breakpoint-up(md){
      padding: 0 0 0 40px;
      width: 45%;
    }

    h3{
      margin-bottom: 1em;
    }

    p{
      margin-bottom: 1em;
    }
  }

  &-link {
    padding: 30px 40px;
    display: block;
    width: 100%;
    color: $dark;
    font-weight: 700;
    font-size: var(--menu-title);
    text-decoration: none !important;
    background: $blue;
    transition: .3s background ease-in-out;

    &:hover {
      background: darken($blue, 10%);
    }

    span:nth-child(1){
      display: block;
      font-size: var(--suptitle);
      text-transform: uppercase;
      margin-bottom: .25em;
    }
  }
}
