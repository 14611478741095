.page-footer-teaser {
  display: block;
  margin-top: -38vw;
  width: 100%;
  height: 55.1020408vw;
  z-index: -1;
  pointer-events: none;
}

.page-footer {
  margin-top: -1px;
  padding: 40px 0;
  background: #9ab5c2;
  color: $black;

  @include media-breakpoint-up(md) {
    padding: 80px 0;
  }

  &, p {
    font-size: var(--p);
    line-height: 1.8;
  }

  a {
    color: $black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    margin-bottom: 30px;
    z-index: auto;

    svg {
      overflow: visible;
      display: block;
      width: 100%;

      .circle {
        fill: $logo-orange;
      }

      .net {
        fill: $white;
      }

      .stick {
        fill: $dark;
      }
    }
  }

  .footer-address {
    a {
      color: $white;
      font-weight: 700;
      transition: .2s color ease-in-out;

      &:hover {
        color: $black;
      }
    }

    strong {
      display: block;
    }
  }

  .footer-address {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .footer-column {
    @include media-breakpoint-up(md) {
      padding-top: 15px;
    }

    &-title {
      width: 100%;
      font-weight: 700;
      font-family: brandon-grotesque, sans-serif;
      text-transform: uppercase;
      margin-bottom: 16px;

      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
        margin-top: 9px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .copyright {
    @extend .h5;
    margin-top: 50px;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      margin-top: 150px;
    }
  }
}
