.image-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;

  // the space to the element above should be the spacing size
  margin-top: calc(15px * 2);
  @include media-breakpoint-down(md) {
    margin-top: calc(10px * 2);
  }

  @include media-breakpoint-down(md) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &--wide {
    align-items: stretch;

    @include media-breakpoint-up(md) {
      max-width: 80%;
    }

    ul {
      max-width: 1950px;
      width: auto;
    }
  }

  ul {
    @extend .content-container;
    margin: -15px;
    padding: 0;
    list-style: none;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      margin: -10px 0;
    }

    &:after {
      content: '';
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 57.3643411%;
      display: block;
      pointer-events: none;
    }
  }

  li {
    position: absolute;
    display: block;
    text-transform: none;

    &:nth-child(1) {
      width: 57.8125%;
      height: 65.5782313%;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      width: 42.1875%;
      height: 42.8571429%;
      top: 0;
      right: 0;
    }

    &:nth-child(3) {
      width: 28.90625%;
      height: 34.4217687%;
      bottom: 0;
      left: 0;

      @include media-breakpoint-down(md) {
        height: 170px !important;
        width: 50% !important;
      }

      span {
        font-size: var(--h4);

        @include media-breakpoint-down(sm) {
          font-size: var(--h5);
        }
      }
    }

    &:nth-child(4) {
      width: 28.90625%;
      height: 34.4217687%;
      bottom: 0;
      left: 28.90625%;

      @include media-breakpoint-down(md) {
        height: 170px !important;
        width: 50% !important;
      }

      span {
        font-size: var(--h4);

        @include media-breakpoint-down(sm) {
          font-size: var(--h5);
        }
      }
    }

    &:nth-child(5) {
      width: 42.1875%;
      height: 57.1428571%;
      bottom: 0;
      right: 0;
    }

    @include media-breakpoint-down(md) {

      &:nth-child(n) {
        position: relative;
        width: 100%;
        height: 240px;
        display: block;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        float: left;
      }
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .image-grid__item {
    position: absolute;
    display: block;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background-color: #9ab5c2;

    @include media-breakpoint-down(md) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0;
      transition: .3s opacity ease-in-out;
      background: $primary;
    }

    &:hover:after {
      opacity: .7;
    }
  }

  &__title {
    font-size: var(--h2);
    line-height: 1.2;
    font-family: brandon-grotesque, sans-serif;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    padding: 0 15px;
    z-index: 2;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 15px rgba(0, 0, 0, .1),  0 0 7px rgba(0, 0, 0, .1),  0 0 5px rgba(0, 0, 0, .1);


    @include media-breakpoint-down(lg) {
      padding: 0 10px;
    }

    small {
      font-size: var(--p-small);
      display: block;
      margin-top: 3px;
    }
  }

  .neos-contentcollection {
    min-width: 0;
  }
}

.hero + .image-grid {
  margin-top: var(--n-sections-gap);
  padding-top: 30px;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 10px;
  }
}
