.text-block {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;

  .suptitle {
    margin-bottom: 10px;
    width: 100%;
    font-weight: 700;
  }

  .content-box {
    padding: 25px;
    margin: 0 -25px;
    background: $white;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-right: 100px;
    white-space: nowrap;

    &:after {
      margin-left: 0;
    }
  }

  p {
    flex-grow: 1;
  }
}
