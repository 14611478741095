// From: https://codepen.io/dusanralic/pen/PoqGKLM
.accordion {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  background-color: $primary;

  &__intro {
    position: relative;
    padding: 20px;
    cursor: pointer;

    span {
      @extend .h5;
      color: #ffffff !important;

     @include media-breakpoint-up(md) {
        font-size: var(--h4);
     }
    }
  }

  &__content {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    will-change: max-height;
    transition: all 0.25s ease-out;
    color: #f1f1f1;
    opacity: 0;

    p, ul, ol {
      font-size: var(--p-small);

      @include media-breakpoint-up(md) {
        font-size: var(--p);
      }
    }
  }

  &__active {
    .accordion__intro {
    }

    .accordion__content {
      padding: 0 20px 20px;
      opacity: 1;
    }

    &--in-backend .accordion__content {
      max-height: 100% !important;
    }
  }
}
