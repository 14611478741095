.glide {
  &__slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .content-container {
      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }
    }

    &s {
      margin: 0;

      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 120px;
    transform: translate(0, -50%);
    width: 50px;
    height: 50px;
    border: none;
    font-size: 0;
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    pointer-events: auto;
    outline: none !important;

    @include media-breakpoint-up(md) {
      top: 50%;
    }

    &:disabled {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 40px;
      height: 40px;
      border-radius: 0;
      border-width: 4px 4px 0 0;
      border-style: solid;
      border-color: $primary;
      transition: .2s transform ease-in-out;
    }

    &s {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      @extend .content-container;
      transform: translate(-50%, -50%);
    }

    &--left {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        left: -50px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(-135deg);
      }

      &:hover:before {
        transform: translate(-70%, -50%) rotate(-135deg);
      }
    }

    &--right {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        right: -50px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:hover:before {
        transform: translate(-30%, -50%) rotate(45deg);
      }
    }
  }
}
