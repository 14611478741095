.Pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: .5rem;

  li.Pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $grey-light;
    height: 3rem;
    width: 3rem;
    border-radius: 2rem;

    &:before {
      display: none;
    }

    & > a {
      text-decoration: none;
    }

    &.isCurrent {
      background: $primary;

      & > a {
        color: $white;
      }
    }
  }
}
