.animated-counters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.animated-counter {
  @include interpolate(--animated-counter-number, sm, xl, 50px, 60px);
  display: inline-block;
  text-align: center;

  @include media-breakpoint-down(xl) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 20px;
  }

  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &__number,
  &__text {
    color: $primary;
    font-size: var(--animated-counter-number);
    font-family: brandon-grotesque, sans-serif;
    text-transform: uppercase;
    line-height: 1.2;
    white-space: nowrap;
  }

  &__text {
    font-size: 19px;
  }
}
