:root {
  @include interpolate(--hero-title, sm, 2096px, 25px, 55px);
  @include interpolate(--h1, sm, xxl, 32px, 50px);
  @include interpolate(--h2, sm, xxl, 28px, 43px);
  @include interpolate(--h3, sm, xxl, 24px, 32px);
  @include interpolate(--h4, sm, xxl, 20px, 25px);
  @include interpolate(--h5, sm, xxl, 18px, 20px);
  @include interpolate(--p, sm, xxl, 16px, 18px);

  @include interpolate(--p-small, sm, xxl, 14px, 16px);
  @include interpolate(--p-big, sm, xxl, 18px, 22px);
  @include interpolate(--subtitle, sm, xxl, 16px, 20px);
  @include interpolate(--suptitle, sm, xxl, 15px, 19px);
  @include interpolate(--menu-link-size, xl, xxl, 16px, 24px);
  @include interpolate(--menu-title, xl, xxl, 18px, 28px);
}

h1, .h1 {
  font-size: var(--h1);
  margin-bottom: .25em;
}

h2, .h2 {
  font-size: var(--h2);
  margin-bottom: .25em;
}

h3, .h3 {
  font-size: var(--h3);
  line-height: 1.25;
  margin-bottom: .25em;
}

h4, .h4 {
  font-size: var(--h4);
}

h5, .h5 {
  font-size: var(--h5);
}

h6, .h6 {
  font-size: var(--h6);
}

.subtitle {
  font-size: var(--subtitle);
  font-weight: 300;
  line-height: 1.23;
}

p, .p {
  font-size: var(--p);
  letter-spacing: .02em;
  line-height: 1.5;
  margin-bottom: 1em;
}

p.suptitle {
  font-size: var(--suptitle);
}

p:last-child {
  margin-bottom: 0;
}

small {

}

mark {
  background: transparent;
  color: $primary;
}


.content-container {
  h1, .h1, h2, .h2, .title {
    color: $primary;
    position: relative;
    display: block;
    flex-direction: column;
    text-align: center;
    font-family: brandon-grotesque, sans-serif;
    text-transform: uppercase;

    &:after {
      content: '';
      display: block;
      clear: both;
      width: 180px;
      min-width: 100px;
      border-bottom: .125em solid;
      margin: .5em auto .675em;
    }
  }

  h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $primary;
    font-family: brandon-grotesque, sans-serif;
    text-transform: uppercase;
    margin: 0 0 .425em;
  }

  h3, .h3 {
    margin-bottom: 0;
  }

  h4, .h4 {
    margin-bottom: 0;
  }

  h5, .h5 {
    margin-bottom: 0;
  }

  h6, .h6 {
    margin-bottom: 0;
  }
}

.with-underscore {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0;

  &:after {
    content: '';
    display: inline-block;
    clear: both;
    width: 180px;
    min-width: 100px;
    border-bottom: .125em solid;
    margin: .5em auto .675em;
  }
}
