.toggle-button {
  outline: none !important;
  display: block;
  position: absolute;
  right: 0;
  top: 18px;
  width: 50px;
  height: 50px;
  z-index: $zindex-fixed - 1;
  overflow: hidden;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  margin: 0 0 0 auto;
  pointer-events: auto;
  will-change: background-color;
  transition: .2s background-color ease-in-out;

  @include media-breakpoint-up(md) {
    top: #{($header-height - $logo-height) / 2}px;
    right: -5px;

    .menu-open .page-header &,
    .page-header.scrolled & {
      @include media-breakpoint-down(xxl) {
        top: 13px;
      }
    }
  }

  .page-header--dark &,
  .page-header.scrolled & {
    span {
      background: $dark;
    }
  }

  span {
    background: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20%;
    min-width: 33px;
    height: 3px;
    pointer-events: none;
    transform: translate(-50%, -50%);

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      will-change: transform, opacity;
      transition: .3s transform ease-in-out, .2s opacity .1s ease-in-out, .2s min-width ease-in-out, .2s background-color ease-in-out;
    }

    &:nth-child(1),
    &:nth-child(3) {
      will-change: transform;
      transition: .3s transform ease-in-out, .3s margin-top ease-in-out, .2s background-color ease-in-out;
    }

    &:nth-child(1) {
      margin-top: -9px;

      @include media-breakpoint-up(md) {
        margin-top: -13px;
      }
    }

    &:nth-child(2) {
      margin-left: -3px;
      min-width: 28px;
    }

    &:nth-child(3) {
      margin-top: 9px;
      margin-left: -6px;
      min-width: 21px;
      transform-origin: left center;
      transition: .3s transform ease-in-out, .3s opacity, .3s min-width ease-in-out, .2s background-color ease-in-out;

      @include media-breakpoint-up(md) {
        margin-top: 13px;
      }
    }
  }

  &.active,
  .menu-open & {
    span {
      background: $dark;
      border-radius: 3px;

      &:nth-child(1) {
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:nth-child(2) {
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(45deg);
        margin-left: 0;
        min-width: 33px;
      }

      &:nth-child(3) {
        opacity: 0;
        min-width: 33px;
        transform: translate(-50%, -50%) scaleX(0);
      }
    }
  }
}
