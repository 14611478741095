.text-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;

  p,
  ul,
  ol {
    font-size: var(--p-big);
  }

  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child) {
    margin-top: 1.2em;
  }

  h4:not(:last-child),
  h5:not(:last-child) {
    margin-top: 0.4em;
  }

  a:not(.show-more) {
    color: $primary;
  }

  &__image {
    width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
  }

  &--has-gray-background {
    background-color: $grey-light;
  }

  &--has-two-columns {
  }

  .icon {
    width: 100px;
    height: auto;
    background: $white;
    border-radius: 100%;
    margin-bottom: 50px;
  }

  ul {
    list-style: none;
    padding-left: 20px;

    li {
      position: relative;

      &:before {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        top: .5em;
        left: -20px;
        @include interpolate(top, sm, xxl, 8px, 11px);
        background: $primary;
        display: inline-block;
      }
    }
  }
}
