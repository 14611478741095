@font-face {
  font-family: "Trade Gothic";
  src: url("#{$path-font}/TradeGothic/Regular.woff2") format("woff2"),
  url("#{$path-font}/TradeGothic/Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("#{$path-font}/TradeGothic/RegularItalic.woff2") format("woff2"),
  url("#{$path-font}/TradeGothic/RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("#{$path-font}/TradeGothic/Bold.woff2") format("woff2"),
  url("#{$path-font}/TradeGothic/Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("#{$path-font}/TradeGothic/BoldItalic.woff2") format("woff2"),
  url("#{$path-font}/TradeGothic/BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
