.logo {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  white-space: nowrap;
  z-index: $zindex-fixed - 1;
  position: relative;
  font-size: 16px;
  padding: 0;
  width: 100px;
  height: 30px;

  @include media-breakpoint-up(md) {
    width: 144px;
    height: #{$logo-height}px;
    font-size: 20px;
    align-items: flex-start;
  }

  svg {
    overflow: visible;
    display: block;
    width: 100%;

    .letter, .stick {
      fill: $dark;
    }

    .circle {
      fill: $logo-orange;
    }

    .net {
      fill: $white;
    }

    .stick {
    }

    .letter {
      @for $i from 1 through 6 {
        $time: #{($i) / 10}s;

        &:nth-child(#{$i}) {
          animation-delay: $time;
        }
      }
    }
  }

  &:hover svg {
    .letter {
      animation-name: fly;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    .net, .stick {
      animation-name: rotate-random;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transform-origin: 100px 100%;
    }
  }
}

@keyframes fly {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  10% {
    transform: translate(5px, -19px) rotate(2deg);
  }
  20% {
    transform: translate(8px, -14) rotate(2deg);
  }
  32% {
    transform: translate(11px, -11px) rotate(2deg);
  }
  45% {
    transform: translate(7px, -12px) rotate(-1deg);
  }
  60%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes rotate-random {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(4deg);
  }
  35% {
    transform: rotate(3deg);
  }
  46% {
    transform: rotate(5deg);
  }
  62% {
    transform: rotate(-2deg);
  }
  80%, 100% {
    transform: rotate(0deg);
  }
}
