*, *::before, *::after {
  box-sizing: border-box;
}

img{
  user-select: none;
}

a{
  color: $primary;
  text-decoration: underline;
}
//css vars
:root {
}
