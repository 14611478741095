$info-window-width: 400;
$info-window-width-sm: 250;
$info-window-height: 200;

.map {
  .gm-style .gm-style-iw-t::after {
    display: none;
  }

  .gm-style-iw {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2);
    border-radius: 0;
    padding: 0 !important;
  }

  .gm-style .gm-ui-hover-effect {
    display: none !important;
  }

  .infowindow {
    width: #{$info-window-width-sm}px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    @include media-breakpoint-up(lg) {
      width: #{$info-window-width}px;
    }

    &__title {
      padding: 15px 30px;
      border-bottom: 1px solid rgba($dark, .2);

      h1, h2, h3, h4, h5 {
        margin-bottom: 0;
      }
    }

    &__content {
      padding: 10px 20px 30px;
    }
  }

  .gm-style-iw {
    overflow: visible;
    transform: translate(-50%, -100%);
    max-height: 500px !important;

    @include media-breakpoint-up(lg) {
      transform: translate(35px, 5px);
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%) rotate(-135deg);
      background: linear-gradient(135deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
      box-shadow: 0 -2px -2px 0 rgba(178, 178, 178, 0.4);

      @include media-breakpoint-up(lg) {
        left: 0;
        top: 25px;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: linear-gradient(135deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
        box-shadow: -2px -1px 2px 0 rgba(178, 178, 178, .4);
      }
    }
  }

  .gm-style-iw-d {
    max-height: 500px !important;
    width: #{$info-window-width-sm}px;
    overflow: hidden !important;

    @include media-breakpoint-up(lg) {
      width: #{$info-window-width}px;
    }
  }
}

.section-map {
  flex-direction: column;
  align-items: center;

  &__content-container {
    @include media-breakpoint-up(md) {
      max-width: 1250px;
      flex: 0 0 90%;
    }
  }

  .show-more {
    font-size: var(--h4);

    &:after {
      top: 3px;
      font-size: var(--p);
    }
  }

  h3 {
    margin: 0;
  }

  .content-container {
    max-height: 110px;
  }

  .section-title {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }

  &--gray {
    background: #f5f5f5;
    margin: var(--sections-gap) 0 0;

    @include media-breakpoint-up(sm) {
      padding-top: calc(var(--sections-gap) * 2);
    }

    .content-container {
      max-height: 100%;
    }
  }
}

table {
  flex-grow: 1;
  width: 100%;
  text-align: left;

  td {
    font-size: var(--p-small);
    padding: 5px 10px;
  }

  td:last-child {
    text-align: right;
  }
}
