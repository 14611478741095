.logo-slider {
  @media (max-width: 768px) {
    margin-right: -25px;
    margin-left: -25px;
  }
}

.glide__slide--logo {
  display: flex;
  aspect-ratio: 4/3;

  & > span,
  & > a {
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      margin: auto;
      padding: 2rem;
      object-fit: contain;

      @media (max-width: 768px) {
        padding: 1rem;
      }
    }
  }
}
