.slider-fluid {
  .image-section {
    padding: 0;
    margin: 0;
  }

  .glide__slides {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .glide__arrows {
    max-width: 100%;
    width: 100%;
  }

  .glide__bullets {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 80px;
    padding: 10px 30px;
    z-index: 22;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;

    @include media-breakpoint-down(md) {
      height: 60px;
    }

    @include media-breakpoint-down(sm) {
      height: 40px;
    }
  }

  .glide__bullet {
    width: 20px;
    height: 20px;
    border: none;
    background: $white;
    box-shadow: none;
    border-radius: 0;
    pointer-events: auto;
    margin-left: 20px;

    @include media-breakpoint-down(md) {
      margin-left: 10px;
    }

    @include media-breakpoint-down(sm) {
      width: 12px;
      height: 12px;
    }

    &--active {
      background-color: $primary;
    }
  }

  .image-section__figure figcaption {
    @include media-breakpoint-down(sm) {
      padding-right: 100px;
    }
  }
}
