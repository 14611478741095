.feature-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;

  &--has-gray-background {
    background-color: $grey-light;
  }

  .section-text {
    font-size: var(--p-big);
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: var(--sections-gap);

    @include media-breakpoint-up(md) {
      gap: 2rem;
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__item {
    flex: 1 1 100%;
    max-width: 100%;
    overflow: hidden;

    &-inner {
      padding: 1rem;
    }

    &__number {
      font-size: var(--h4);
      font-weight: bold;
      color: $primary;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: var(--h3);
      }
    }

    &__description {
      font-size: var(--p-small);

      @include media-breakpoint-up(md) {
        font-size: var(--p);
      }
    }
  }

  &--three-columns .feature-list__item {
    @include media-breakpoint-up(md) {
      flex: 0 0 calc(33% - 1.1rem);
      max-width: calc(33% - 1.1rem);
    }
  }

  .icon {
    display: block;
    width: 100px;
    height: 100px;
    background: $white;
    margin-bottom: 1rem;
  }

  &--center-items-content &__item {
    text-align: center;

    .icon {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--has-items-with-grey-background &__item {
    background: $grey-light;
  }
}
