.title {
  margin: 0 auto;
  text-align: left;
  position: relative;
  padding: var(--sections-gap) 0 0.5em;
  border-bottom: .125em solid;
  width: calc(100% - #{$grid-gutter-width});

  @include media-breakpoint-up(md) {
    width: calc(60% - #{$grid-gutter-width});
    min-width: calc(768px - #{$grid-gutter-width});
  }
}
