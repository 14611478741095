.contact-person-list {
  background: #f5f5f5;

  @include media-breakpoint-up(md) {
    .container {
      max-width: 80%;
    }
  }

  &__text {
    margin-bottom: 3rem;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 3rem;

    &__image {
      width: 33.3%;
      max-width: 180px;
      aspect-ratio: 1/1;
      height: auto;
    }
  }
}

main section:last-child:is(.contact-person-list) {
  margin-bottom: var(--n-sections-gap)
}
