.image-section {
  position: relative;
}

.lightbox-image {
  display: block;
  width: 100%;
  height: auto;
}

.lightbox {
  position: fixed;
  top: 76px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 900;

  @include media-breakpoint-down(sm) {
    top: 85px;
  }

  img {
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
  }

  &__image-wrapper {
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
  }

  &__open,
  &__close {
    color: $white;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    z-index: 50;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  &__open {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__open:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 0 3px rgba(#000, .3));
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cg transform='translate(0.000000,511.000000) scale(0.100000,-0.100000)' fill='%23fff'%3E%3Cpath d='M6472.3,4526.9v-483.1h878.8h881.1L6971.5,2783.1L5713.1,1524.8l349.7-352l352-349.7l1258.4,1258.4l1260.7,1260.6v-881.1v-878.8h483.1H9900v1713.9V5010H8186.2H6472.3V4526.9z'/%3E%3Cpath d='M2324.6-1863.8L1066.2-3122.2v881.1v878.8H583.1H100v-1713.8V-4790h1713.8h1713.9v483.1v483.1h-878.8h-881.1L3017-2574.6c685.5,685.5,1246.9,1258.4,1246.9,1269.9c0,13.8-151.8,177.1-340.5,363.5l-338.2,338.2L2324.6-1863.8z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &__close {
    &:before {
      content: '';
      width: 22px;
      height: 22px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0 0 3px rgba(#000, .3));
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cg fill='%23fff'%3E%3Cpath d='M157,10l343,343L843,10l147,147L647,500l343,343L843,990L500,647L157,990L10,843l343-343L10,157L157,10z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  lottie-player {
    pointer-events: none;
  }
}
