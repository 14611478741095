.page-teasers {
  &__item {
    display: block;
    text-decoration: none !important;
    background: $primary;
    height: 100%;
    padding: 40px 50px;
    box-shadow: 0 0 0 10px $primary inset;
    transition: .2s background-color ease-in-out;
    text-align: center;
    max-width: 550px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      padding: 30px;
      max-width: 360px;
    }

    @include media-breakpoint-down(sm) {
      padding: 20px 15px;

      &:first-child {
        height: auto;
        margin-bottom: 20px;
      }
    }

    p {
      margin: 0;
    }

    p, .h3 {
      color: $white;
      transition: .2s color ease-in-out;
    }

    .h3:after {
      max-width: 75px;
      background: $white;
      transition: .2s background-color ease-in-out;
    }
  }

  &__item:hover {
    background: transparent;

    p, .h3 {
      color: $primary;
    }

    h3:after {
      background: $primary;
    }
  }
}
