.page-header {
  color: $menu-color;
  padding: 0;
  margin: 0;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 1060;
  pointer-events: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  transition: .3s background-color ease-in-out;

  .menu-open &,
  &.scrolled {
    @include media-breakpoint-down(xxl) {
      background: rgba(255, 255, 255, 0.8);
    }
    @include media-breakpoint-down(md) {
      background: $white;
    }
  }

  .logo .letter {
    fill: $white;
  }

  &--dark,
  &.scrolled,
  .menu-open & {
    .logo .letter {
      fill: $dark;
    }
  }

  .collapsible {
    padding: 20px 0;
    flex-grow: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    bottom: 0;
    background-color: $menu-bg;
    z-index: 20;
    pointer-events: none;
    backdrop-filter: blur(5px);
    opacity: 0;
    will-change: opacity;
    transition: .3s opacity .3s ease-in-out;

    & > .container {
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      max-width: 500px;
      padding: 85px 50px;
      background: $white;
      overflow-scrolling: touch;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      opacity: 0;
      transform: translate(var(--scrollbar-width), 0);
      transition: .3s opacity .2s ease-in-out, .2s box-shadow ease-in-out;

      @media screen and (max-height: 768px) {
        justify-content: flex-start;
      }

      @include media-breakpoint-up(md) {
        padding: #{$header-height}px 50px;
        max-width: 500px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 1000px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        padding: 85px 25px 50px;
      }
    }
  }
}

.menu-open {
  .page-header {
    background: transparent;
    transition: .3s background-color ease-in-out;
    padding-right: var(--scrollbar-width);

    .collapsible {
      opacity: 1;
      pointer-events: auto;
      transition: .3s opacity ease-in-out;
      padding-right: var(--scrollbar-width);

      & > .container {
        opacity: 1;
        transform: translate(0, 0);
        box-shadow: 0 0 25px rgba(0, 0, 0, .15);
      }
    }
  }

  .navigation-menu li {
    transform: translate(0, 0);
    opacity: 1;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(1, .5, 0, .5);

    @for $i from 1 through 10 {
      $time: #{0.3 + ($i * 0.1)}s;

      &:nth-child(#{$i}) {
        transition-delay: $time;
      }
    }
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  position: relative;
  padding: 0;
  user-select: none;

  &.active &-brand {
    @include media-breakpoint-down(sm) {
      background: #fff;
      width: 100%;
      transition: padding-top 0.2s, padding-bottom 0.2s, 1s background-color 0.2s;
    }
  }

  &-brand {
    margin: 0 auto 0 0;
    padding-top: 25px;
    padding-bottom: 30px;
    padding-right: 15px;
    position: relative;
    z-index: 30;
    overflow: hidden;
    display: block;
    pointer-events: auto;
    transition: padding-top 0.2s, padding-bottom 0.2s, .2s background-color .2s;

    @include media-breakpoint-up(md) {
      padding-top: #{($header-height - $logo-height) * .4}px;
      padding-bottom: #{($header-height - $logo-height) * .6}px;

      .menu-open .page-header &,
      .page-header.scrolled & {
        @include media-breakpoint-down(xxl) {
          padding-top: 12px;
          padding-bottom: 21px;
        }
      }
    }
  }

  &-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0 0 0 auto;
    width: 100%;

    li {
      margin-bottom: 2.5em;
      opacity: 0;
      transform: translate(200px, 0);
      transition-property: opacity, transform;
      transition-duration: .1s;
      transition-delay: .5s;
      transition-timing-function: linear;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 2em;
      }
    }

    a {
      @extend .h3;
      font-family: brandon-grotesque, sans-serif;
      color: $menu-color;
      text-decoration: none;
      position: relative;
      padding: 10px 0;
      margin: 0;
      text-transform: uppercase;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 0;
        min-width: 100px;
        border-bottom: .125em solid;
        opacity: 0;
        transform-origin: left center;
        transform: scaleX(0);
        transition: .2s transform ease-in-out, .2s opacity ease-in-out;
      }

      &:hover:after {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
}
