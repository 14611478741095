.blog-overview-item__date-and-estimated-reading-time,
.blog-post__header {
  color: #777777;
  & > svg {
    fill: #777777;
    height: 1rem;
    margin-top: -0.2rem;
    margin-right: 0.5rem;
  }
}
