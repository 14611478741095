.cc-revoke {
  padding-left: 0;
}

.cc-window {
  max-width: 26rem !important;
  padding: 27px 32px 24px !important;
  color: $dark;
  background: $white;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, .4);
  border-radius: 0;
  transition: .3s box-shadow ease-in-out;

  a {
    text-decoration: none !important;
  }
}

.cc-message {
  margin: 0 0 24px !important;
}
