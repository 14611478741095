html {
  margin: 0;
  padding: 0;
  // Header height at > 768px
  scroll-padding-top: 76px;

  @media screen and (max-height: 768px) {
    // Header height at <= 768px
    scroll-padding-top: 85px;
  }
}


body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  overflow-x: hidden;
  will-change: opacity;
  transition: .3s opacity ease-in-out;

  &.menu-open {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    padding-right: var(--scrollbar-width);
  }
}

main {
  padding-top: 0;
  padding-bottom: var(--sections-gap);
  width: 100%;
  max-width: 100vw;
  flex-grow: 1;
  align-items: stretch;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 20;
  position: relative;
}

.content-container {
  justify-self: center;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: $grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;

  @include media-breakpoint-up(md) {
    min-width: 768px;
    max-width: 60%;
    flex: 0 0 60%;
  }

  &.row {
    padding: 0;
  }
}

section {
  width: 100%;
  padding: var(--sections-gap) 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.section {
  &--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 133px !important;
    left: 0;
    z-index: 50;
    padding-top: 0;
    padding-bottom: 0;
    transition: .2s top ease-in-out, .2s padding ease-in-out;

    .page-header.scrolled ~ & {
      background: rgba($white, .85);
      top: 76px !important;
    }
  }


  .menu-open &,
  &--is-stuck {
    &.section--background-white {
      background: rgba($white, .85);

      @include media-breakpoint-down(md) {
        background: $white !important;
      }
    }
  }
}

:root {
  @include interpolate(--sections-gap, sm, xxl, 25px, 50px);
  @include interpolate(--n-sections-gap, sm, xxl, -25px, -50px);
  @include interpolate(--sections-gap-x2, sm, xxl, 50px, 100px);
  @include interpolate(--sections-gap-half, sm, xxl, 12px, 25px);
}
